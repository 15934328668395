import React, { useRef, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";

const NoticeDialog = (props) => {
  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            {/* <h4 class="modal-title">Notice</h4> */}
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                {props.noticeData &&
                  props.noticeData.map((item, index) => (
                    <div
                      className={`carousel-item ${index == 0 ? "active" : ""}`}
                      active
                      data-index={index}
                    >
                      {/* <h4
                        className="text-center"
                        style={{ paddingBottom: "15px", fontWeight: "bold" }}
                      >
                        {item.title}
                      </h4> */}

                      {/* <div className="text-center">
                        <h6>{item.description}</h6>
                      </div> */}
                      <img
                        class="d-block w-100 pt-4"
                        src={item.image}
                        alt={item.title}
                      />
                    </div>
                  ))}
              </div>

              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span class="carousel-control-custom-icon" aria-hidden="true">
                  <i class="fas fa-chevron-left"></i>
                </span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span class="carousel-control-custom-icon" aria-hidden="true">
                  <i class="fas fa-chevron-right"></i>
                </span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default NoticeDialog;
