import React, { useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import NsdlPanCardService from "../../../services/Admin/NsdlPanCardService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

const NsdlPanCardReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [productId, setProductId] = useState();
  const [userId, setUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    NsdlPanCardService.report(
      token,
      fromDate,
      toDate,
      status,
      productId,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },

    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.product_id === 48) {
              return <span>PAN Type: Physical PAN</span>;
            } else if (row.product_id === 50) {
              return <span>PAN Type: E-PAN</span>;
            }
          })()}
          <br />
          <span>App Type: {row.optional1}</span>
        </div>
      ),
    },
    {
      name: "Applicant Details",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Name: {row.optional4}</span> <br />
          <span>Mobile: {row.optional7}</span> <br />
          <span>Email: {row.optional8}</span> <br />
          <span>Gender: {row.optional6 === "M" ? "Male" : "Female"}</span>
          <br />
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            Order Amount:
            <span style={{ fontWeight: "bold" }}> ₹{row.order_amount}</span>
          </span>
          <br />
          <span>
            Debit Amount:
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              -₹{row.amount}
            </span>
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "ACK No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.operator_id ? row.operator_id : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Error Msg",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.secret_optional2 ? row.secret_optional2 : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const [summaryData, setSummaryData] = useState();

  const getSummary = async () => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPanCardService.summary(
        token,
        fromDate,
        toDate,
        productId,
        userId
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPanCardService.xlsReport(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="NSDL PAN Card Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">NSDL PAN Card Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option value="">Select PAN Type</option>
                                  <option value="48">Physical PAN</option>
                                  <option value="50">E-PAN</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-secondary mr-4"
                                  onClick={() => {
                                    getSummary();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Fetch Summary
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-outline-primary btn-icon waves-effect waves-light"
                                  onClick={xlsReport}
                                >
                                  <i class="fa fa-download"></i> EXPORT
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.success
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL PENDING</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.pending
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL FAILED</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.failed
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </React.Fragment>
  );
};

export default NsdlPanCardReport;
