import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/User/AuthService";
import BbpsService from "../../../../services/User/BbpsService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const BillPaymentReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [productId, setProductId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _status = params.get("status");

    if (_status) {
      setStatus(_status);
      await fetchData(_status);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const fetchData = async (_status) => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    BbpsService.report(
      token,
      fromDate,
      toDate,
      _status ? _status : status,
      productId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    // {
    //   name: "Category",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       {row.product.name}
    //     </div>
    //   ),
    // },
    {
      name: "Operator",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {row.optional5}
        </div>
      ),
    },
    {
      name: "Number",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            {row.optional1}
          </span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            ₹{row.order_amount}
          </span>
        </div>
      ),
    },
    {
      name: "Commission",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{ fontSize: "16px", fontWeight: "bold", color: "green" }}
          >
            ₹{row.commercial_amount}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Response",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <span>
                  {row.secret_optional2 ? row.secret_optional2 : "NULL"}
                </span>
              );
            } else {
              return <span>N/A</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Operator Ref ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.operator_id ? row.operator_id : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Receipt",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            class="btn btn-primary btn-sm"
            onClick={() => {
              downloadReceiptHandler(row.order_id);
            }}
          >
            <i class="fas fa-download mr-2"></i> Download
          </button>
        </div>
      ),
    },
  ];

  const downloadReceiptHandler = async (orderId) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await BbpsService.receipt(token, orderId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;

        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const operators = [
    { value: 1, label: "Airtel", image: "/images/airtel_logo.png" },
    { value: 2, label: "BSNL Topup", image: "/images/bsnl_logo.png" },
    { value: 3, label: "BSNL Special", image: "/images/bsnl_logo.png" },
    { value: 4, label: "JIO", image: "/images/jio_logo.png" },
    { value: 5, label: "Vi", image: "/images/vi_logo.png" },
    {
      value: 6,
      label: "Airtel Digital TV",
      image: "/images/airtel_digital_tv_logo.png",
    },
    { value: 7, label: "Dish TV", image: "/images/dish_tv_logo.png" },
    { value: 8, label: "Sun Direct", image: "/images/sun_direct_logo.png" },
    { value: 9, label: "Tata Sky", image: "/images/tata_sky_logo.png" },
    {
      value: 10,
      label: "Videocon D2H",
      image: "/images/videocon_d2h_logo.png",
    },
    {
      value: 47,
      label: "Google Play",
      image: "/images/google-play-icon-2.png",
    },
  ];

  const getLogo = (row) => {
    let obj = operators.find((o) => o.label === row.product.name);
    if (obj) {
      return obj.image;
    }
    return "";
  };

  const [isLoading, setIsloading] = useState(false);

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Bill Payment Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Bill Payment Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={status ? status : ""}>
                                    {status ? status : "Select Status"}{" "}
                                  </option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default BillPaymentReport;
