import common from "../../utill/Common";

class CompanyService {
  fetchCompanyDetails = async (token) => {
    const url = common.baseUrl + "admin/company/fetch_company_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateCompanyDetails = async (
    token,
    company_name,
    legal_name,
    primary_domain,
    android_app,
    phone,
    email,
    address,
    pan_number,
    gstin,
    nsdl_id_charges
  ) => {
    const url = common.baseUrl + "admin/company/update_company_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        company_name,
        legal_name,
        primary_domain,
        android_app,
        phone,
        email,
        address,
        pan_number,
        gstin,
        nsdl_id_charges,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateEmailSettings = async (
    token,
    email,
    encryption,
    smtp_host,
    smtp_port,
    smtp_username,
    smtp_password
  ) => {
    const url = common.baseUrl + "admin/company/update_email_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        email,
        encryption,
        smtp_host,
        smtp_port,
        smtp_username,
        smtp_password,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateBrandingSettings = async (token, title_text, footer_text) => {
    const url = common.baseUrl + "admin/company/update_branding_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        title_text,
        footer_text,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateStorageSettings = async (
    token,
    default_storage,
    access_key_id,
    secret_access_key,
    region,
    bucket
  ) => {
    const url = common.baseUrl + "admin/company/update_storage_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        default_storage,
        access_key_id,
        secret_access_key,
        region,
        bucket,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateWhatsappSettings = async (
    token,
    url,
    method,
    headers,
    content_type,
    body,
    json_body
  ) => {
    const url1 = common.baseUrl + "admin/company/update_whatsapp_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        url,
        method,
        headers,
        content_type,
        body,
        json_body,
      }),
    };

    const response = await fetch(url1, options);

    return response.json();
  };

  updateSmsSettings = async (
    token,
    url,
    method,
    headers,
    content_type,
    body,
    json_body
  ) => {
    const url1 = common.baseUrl + "admin/company/update_sms_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        url,
        method,
        headers,
        content_type,
        body,
        json_body,
      }),
    };

    const response = await fetch(url1, options);

    return response.json();
  };

  updatePGSettings = async (token, jsonData) => {
    const url = common.baseUrl + "admin/company/update_pg_settings";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: jsonData,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateFlashMessage = async (token, flash_message) => {
    const url = common.baseUrl + "admin/company/update_flash_message";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ flash_message }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateSliderImages = async (token, sliderImages) => {
    const url = common.baseUrl + "admin/company/update_slider_images";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ slider_images: sliderImages }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  sendTestMail = async (token, email) => {
    const url = common.baseUrl + "admin/company/send_test_mail";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        email,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  uploadLogo = async (token, type, logo) => {
    let endpoint;

    switch (type) {
      case "logo":
        endpoint = "upload_logo";
        break;

      case "white_logo":
        endpoint = "upload_white_logo";
        break;

      case "favicon":
        endpoint = "upload_favicon";
        break;

      default:
        break;
    }

    const url = common.baseUrl + "admin/company/" + endpoint;

    const dataArray = new FormData();
    dataArray.append(type, logo);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getLogo = async (token, type) => {
    const url = common.baseUrl + "admin/company/get_logo";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CompanyService();
