import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// import SimpleBar from "simplebar-react";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "./../../../../store/loading-context";

const SubsOrderReviewDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const pinRef = useRef();

  const payNowHandler = async (e) => {
    e.preventDefault();

    const pin = pinRef.current.value;

    if (!pin) {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = await AuthService.getUserID();

      const responseData = await NsdlPANCardService.subscription(
        token,
        userId,
        pin
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.handleClose();
        props.handleSubscribed();

        const user = JSON.parse(localStorage.getItem("user"));
        user.enable_nsdl_service = "YES";
        localStorage.setItem("user", JSON.stringify(user));

        //Swal.fire("Success!", message, "success");
      } else {
        if (message == "You have already subscribied") {
          props.handleClose();
          props.handleSubscribed();

          const user = JSON.parse(localStorage.getItem("user"));
          user.enable_nsdl_service = "YES";
          localStorage.setItem("user", JSON.stringify(user));

          //Swal.fire("Success!", message, "success");
        } else {
          props.handleOpen();
          Swal.fire("Opps!", message, "error");
        }
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.open} toggle={props.handleClose} centered>
        <ModalHeader>
          <h5 className="modal-title" id="myModalLabel">
            Order Review
          </h5>
        </ModalHeader>
        <ModalBody>
          {/* <SimpleBar> */}
          <ListGroup className="mb-1">
            <ListGroupItem>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex">
                    <div className="flex-shrink-0 ms-2">
                      <h6 className="fs-14 mb-0">Order Amount</h6>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <span className="text-danger">₹{props.amount}</span>
                </div>
              </div>
            </ListGroupItem>

            <ListGroupItem>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex">
                    <div className="flex-shrink-0 ms-2">
                      <h6 className="fs-14 mb-0">Payable Amount</h6>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <span className="text-danger">₹{props.amount}</span>
                </div>
              </div>
            </ListGroupItem>
          </ListGroup>
          {/* </SimpleBar> */}

          <div className="mb-3 mt-3">
            <div className="input-group">
              <Input
                className="form-control"
                placeholder="Enter Transaction PIN"
                type="password"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                maxLength="4"
                innerRef={pinRef}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={props.handleClose}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={payNowHandler}>
            Pay Now
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SubsOrderReviewDialog;
