import React, { useEffect, useContext, useState, useRef } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import AuthService from "../../../services/User/AuthService";
import CommonService from "../../../services/User/CommonService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import LoginOtpDialog from "./LoginOtpDialog";
//import LoginOtpDialog from "./LoginOtpDialog";

// import "./Login.module.css";

const Login = () => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const [errMsg, setErrMsg] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [token, setToken] = useState(true);
  const [isAgree, setIsAgree] = useState(false);

  const userIdRef = useRef();
  const passwordRef = useRef();

  const loginHandler = async (e) => {
    e.preventDefault();

    const userId = userIdRef.current.value;
    const password = passwordRef.current.value;

    if (!latitude || !longitude) {
      // Swal.fire(
      //   "Opps!",
      //   "Unable to retrieve your location, please enable location access in your browser",
      //   "error"
      // );
      // return;
    }

    if (!isAgree) {
      Swal.fire(
        "Opps!",
        "Please accept to the Terms & Conditions and Privacy Policy",
        "error"
      );
      return;
    }

    if (!userId) {
      Swal.fire("Opps!", "Invalid Mobile ID", "error");
      return;
    }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const data = await AuthService.sendOtp(
        userId,
        password,
        latitude,
        latitude
      );

      if (!data) {
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS" && message !== "LOGIN_SUCCESS") {
        const token = data.data.token;
        setToken(token);
        setErrMsg(message);
        setOpen(true);
      } else if (status === "SUCCESS" && message === "LOGIN_SUCCESS") {
        const accessToken = data.data.access_token;
        const name = data.data.name;
        const user_id = data.data.user_id;
        const user_type = data.data.user_type;
        const mobile = data.data.mobile;
        const email = data.data.email;
        const fpay_outlet_onboarded = data.data.fpay_outlet_onboarded;
        const enable_nsdl_service = data.data.enable_nsdl_service;

        localStorage.setItem("timestamp", new Date().getTime());
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: name,
            user_type: user_type,
            user_id: user_id,
            mobile: mobile,
            email: email,
            enable_nsdl_service: enable_nsdl_service,
          })
        );

        if (fpay_outlet_onboarded == "YES") {
          localStorage.setItem("is_outlet", true);
        } else {
          localStorage.setItem("is_outlet", false);
        }

        //history.push("/user/dashboard");
        window.location.href = "user/dashboard";
        // window.location.reload();
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      Swal.fire(
        "Opps!",
        "Geolocation is not supported by your browser",
        "error"
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {
          Swal.fire(
            "Opps!",
            "Unable to retrieve your location, please enable location access in your browser",
            "error"
          );
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
    isLoggedIn();
    //  fetchData();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIsAgree = (v) => {
    setIsAgree(v);
  };

  // ** AUTO LOGIN ** //
  const isLoggedIn = () => {
    const token = AuthService.getAccessToken();
    if (token) {
      window.location.href = "user/dashboard";
    }
  };
  // ** AUTO LOGIN ** //

  const [imgBase64, setImgBase64] = useState();
  const [cEmail, setCEmail] = useState();
  const [cMobile, setCMobile] = useState();
  const [companyName, setCompanyName] = useState();

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const responseData = await CommonService.getDomainInfo(
        window.location.hostname
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setImgBase64(responseData.data.logo);
        setCMobile(responseData.data.mobile);
        setCEmail(responseData.data.email);
        setCompanyName(responseData.data.company_name);

        localStorage.setItem(
          "company_info",
          JSON.stringify({
            logo: responseData.data.logo,
            mobile: responseData.data.mobile,
            email: responseData.data.email,
            company_name: responseData.data.company_name,
          })
        );
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="login-box">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <div class="h1">
              <img src="/assets/img/logo.png" alt="logo" width="150px" />

              {/* <h4>
                <strong>{companyName} </strong>
              </h4> */}
            </div>
          </div>
          <div class="card-body">
            <h5 className="text-center">Welcome Back!</h5>
            <p class="login-box-msg">Login to access to your account.</p>
            <form autoComplete="off">
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={userIdRef}
                  class="form-control"
                  placeholder="Mobile Number"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <input
                  type="password"
                  ref={passwordRef}
                  class="form-control"
                  placeholder="Password"
                />
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <div class="icheck-primary">
                    <input
                      type="checkbox"
                      id="remember"
                      onChange={(e) => {
                        //  alert(e.target.checked);
                        handleIsAgree(e.target.checked);
                      }}
                    />
                    <label for="remember" style={{ fontWeight: "normal" }}>
                      I agree to the T&C and Privacy Policy
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <button
                  type="button"
                  onClick={loginHandler}
                  class="btn btn-primary btn-block"
                >
                  Login
                </button>
              </div>

              <div class="row mt-2">
                <div class="col-12 mx-auto">
                  <p class="float-right">
                    <a href="/forgot_password">I forgot my password</a>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mx-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.fispl.snppay"
                    target="_blank"
                  >
                    <img
                      src="assets/img/google-play-icon.png"
                      alt="logo"
                      height="40px"
                    />
                  </a>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12 mx-auto">
                  <p class="text-center">
                    Don't have an account? <a href="/signup">Signup</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <LoginOtpDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        errMsg={errMsg}
        token={token}
      />
    </React.Fragment>
  );
};

export default Login;
