import React, { useState, useEffect } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";

// import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import EditIcon from "@mui/icons-material/Edit";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Tree from "react-animated-tree";

// import TreeView from "@mui/lab/TreeView";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import TreeItem from "@mui/lab/TreeItem";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import DbModuleService from "../../../services/User/DbModuleService";
import ViewProfileDialog from "./ViewProfileDialog";
import FundTransferDialog from "./FundTransferDialog";

const UserList = () => {
  const [open, setOpen] = useState(false);
  const [openFtDialog, setOpenFtDialog] = useState(false);

  const [fData, setFData] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const size = 10;

  useEffect(() => {
    fetchUsers();
  }, [page, searchText]);

  const fetchUsers = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    DbModuleService.list(token, searchText, page, size)
      .then((response) => {
        const data = response;

        if (data.status !== "SUCCESS") {
          throw new Error(data.message);
        }
        const dataResult = data.data.result;
        setCount(data.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.user_type}</span>
        </div>
      ),
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.user_id}</span>
        </div>
      ),
    },

    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>{row.name}</b>
        </div>
      ),
    },
    {
      name: "Mobile",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.mobile}</span>
        </div>
      ),
    },
    {
      name: "Email",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.email}</span>
        </div>
      ),
    },
    {
      name: "State",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.state}</span>
        </div>
      ),
    },
    {
      name: "Main Wallet",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {" "}
            ₹
            {row.tbl_wallet_balances
              ? row.tbl_wallet_balances.length > 0
                ? row.tbl_wallet_balances[0].main_wallet
                : "null"
              : row.tbl_wallet_balance
              ? row.tbl_wallet_balance?.main_wallet
              : "null"}
          </span>
        </div>
      ),
    },
    {
      name: "Reg Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {/* <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setFData(row);
              setOpen(true);
            }}
          >
            <Visibility />
          </IconButton> */}

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setFData(row);
              setOpenFtDialog(true);
            }}
          >
            <AccountBalanceWallet />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseFtDialog = () => {
    setOpenFtDialog(false);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="User List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <DataTableComponent
                          noHeader={true}
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={count}
                          paginationPerPage={size}
                          onChangePage={(page) => {
                            setPage(page);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ViewProfileDialog
          open={open}
          handleClose={handleClose}
          fData={fData}
          fetchUsers={fetchUsers}
        />

        <FundTransferDialog
          openFtDialog={openFtDialog}
          handleCloseFtDialog={handleCloseFtDialog}
          fData={fData}
        />
      </Templete>
    </React.Fragment>
  );
};

export default UserList;
